import { createEvent, createStore, sample } from 'effector'
import { createGate } from 'effector-react'
import { persist } from 'effector-storage/local'
import { delay, once, reset, throttle } from 'patronum'
import { isDesktop, isMobile, isTablet } from 'react-device-detect'
import { NavigateFunction } from 'react-router-dom'
import { updateApiServiceTokenFx } from '~/app/hocs/with-token-refresh'
import { onboardingSessionModel } from '~/entities/onboarding-session'
import { PopupType, popupModel } from '~/entities/popup'
import { viewerModel } from '~/entities/viewer'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { AppRoute, LocalStorageKeys, ManageUrlQueryKeys } from '~/shared/config'
import { urlParamActions } from '~/shared/hooks'
import { atom, bridge } from '~/shared/lib/factory'
import { isiOS, isSafari } from '~/shared/lib/is-ios'
import { navigationModel } from '~/shared/models/navigation'

export const handleRedirectModel = atom(() => {
  const Gate = createGate<{
    navigate: NavigateFunction
  }>()

  const resetState = createEvent()

  const $computeRedirectTo = createStore(false)
  const $redirectTo = createStore<string | null>(null)

  // Track analytics if user came from email
  // Skip tracking if analytics credentials are not provided
  sample({
    clock: Gate.open,
    filter: () => {
      return !process.env.AA_MIXPANEL_TOKEN
    },
    fn: () => true,
    target: $computeRedirectTo,
  })

  sample({
    clock: analyticsModel.$analytics,
    filter: () => {
      const redirectToQuery = urlParamActions().getParam(ManageUrlQueryKeys.EMAIL_TYPE)
      return !!redirectToQuery
    },
    fn: () => {
      return {
        name: eventNames.userEngagementEmailPageOpened,
        properties: {
          emailType: urlParamActions().getParam(ManageUrlQueryKeys.EMAIL_TYPE),
        },
      }
    },
    target: [analyticsModel.track, $computeRedirectTo],
  })

  bridge(() => {
    sample({
      clock: $computeRedirectTo,
      filter: () => {
        const redirectToQuery = urlParamActions().getParam(ManageUrlQueryKeys.REDIRECT_TO)
        return !!redirectToQuery
      },
      fn: () => {
        const redirectToQuery = urlParamActions().getParam(ManageUrlQueryKeys.REDIRECT_TO)
        urlParamActions().deleteParams([
          ManageUrlQueryKeys.EMAIL_TYPE,
          ManageUrlQueryKeys.REDIRECT_TO,
        ])
        return redirectToQuery ?? null
      },

      target: $redirectTo,
    })
    sample({
      clock: Gate.open,
      target: [
        onboardingSessionModel.onboardingFinished,
        onboardingSessionModel.onboardingSkipped,
      ],
    })
  })

  bridge(() => {
    sample({
      clock: viewerModel.$accessToken,
      target: updateApiServiceTokenFx,
    })
  })

  bridge(() => {
    sample({
      clock: Gate.open,
      source: {
        isAuthorized: viewerModel.$isAuthorized,
        visiblePopup: popupModel.$visiblePopup,
      },
      filter: ({ isAuthorized }) => !isAuthorized,
      fn: ({ visiblePopup }) => {
        return visiblePopup ? visiblePopup : PopupType.Login
      },
      target: popupModel.visiblePopupChanged,
    })

    const resetForAuthorized = createEvent()

    sample({
      clock: Gate.open,
      target: resetForAuthorized,
    })

    const onceTrigger = once({
      source: updateApiServiceTokenFx.doneData,
      reset: resetForAuthorized,
    })

    const onceTriggerWithDelay = delay({ source: onceTrigger, timeout: 200 })
    const onceTriggerWithThrottle = throttle({
      source: onceTriggerWithDelay,
      timeout: 200,
    })
    sample({
      clock: onceTriggerWithThrottle,
      source: {
        isAuthorized: viewerModel.$isAuthorized,
        redirectTo: $redirectTo,
        gateStatus: Gate.status,
      },
      filter: ({ isAuthorized, gateStatus }) => {
        return !!isAuthorized && gateStatus && isDesktop && !isMobile && !isTablet
      },
      fn: ({ redirectTo }) =>
        redirectTo ? redirectTo! : `${AppRoute.Destinations}/trip-id`,
      target: [navigationModel.pathChanged, resetState],
    })

    const addDelayForAndroid = delay({ source: onceTrigger, timeout: 200 })
    const onceTriggerWithThrottleAndroid = throttle({
      source: addDelayForAndroid,
      timeout: 200,
    })
    sample({
      clock: onceTriggerWithThrottleAndroid,
      source: {
        isAuthorized: viewerModel.$isAuthorized,
        redirectTo: $redirectTo,
        gateStatus: Gate.status,
      },
      filter: ({ isAuthorized, gateStatus }) => {
        return !!isAuthorized && gateStatus && !isDesktop && !isSafari && !isiOS
      },
      fn: ({ redirectTo }) =>
        redirectTo ? redirectTo! : `${AppRoute.Destinations}/trip-id`,
      target: [navigationModel.pathChanged, resetState],
    })

    const addDelayForIOS = delay({ source: onceTrigger, timeout: 200 })
    const onceTriggerWithThrottleIos = throttle({
      source: addDelayForIOS,
      timeout: 200,
    })
    sample({
      clock: onceTriggerWithThrottleIos,
      source: {
        isAuthorized: viewerModel.$isAuthorized,
        redirectTo: $redirectTo,
        gateStatus: Gate.status,
      },
      filter: ({ isAuthorized }) => {
        return !!isAuthorized && !isDesktop && (isMobile || isTablet) && isiOS
      },
      fn: ({ redirectTo }) => {
        const redirectToUrl = redirectTo ? redirectTo : `${AppRoute.Destinations}/trip-id`
        return redirectToUrl
      },
      target: [navigationModel.pathChanged, resetState],
    })
  })

  reset({
    clock: resetState,
    target: [$redirectTo, popupModel.$visiblePopup],
  })

  persist({ store: $redirectTo, key: LocalStorageKeys.SelectedRedirection })
  return {
    Gate,

    $redirectTo,
    resetState,
  }
})
