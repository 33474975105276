import { useEffect } from 'react'

export const urlParamActions = () => {
  const url = new URL(window.location.href)
  const searchParams = new URLSearchParams(url.search)
  const getParam = (paramName: string) => {
    return searchParams.get(paramName)
  }
  const deleteParams = (paramNames: string[]) => {
    paramNames.forEach((name) => {
      searchParams.delete(name)
    })
    url.search = searchParams.toString()
    window.history.replaceState({}, document.title, url.toString())
  }
  return { getParam, deleteParams }
}

export const useAddToLocalStorage = (idsToSetToLocalStorage: string[]) => {
  const location = window.location
  const params = new URLSearchParams(location.search)

  useEffect(() => {
    idsToSetToLocalStorage.forEach((id) => {
      const value = params.get(id)
      value && window.localStorage.setItem(id, value)
    })
  }, [idsToSetToLocalStorage])
}
