export enum LocalStorageKeys {
  Viewer = '@away/viewer',
  Token = '@away/token',
  RefreshToken = '@away/refresh-token',
  LandingPageUrl = '@away/landing-page-url',
  ReferralCode = '@away/referral-code',

  Trips = '@away/trips',
  TripId = '@away/trip-id',
  TripsStatus = '@away/trips-status',
  TripsItineraries = '@away/trips-itineraries',
  SelectedDestination = '@away/selected-destination',
  SelectedOrigin = '@away/selected-origin',
  GreetingMessage = '@away/greeting-message',
  ExplanationMessage = '@away/explanation-message',

  // IsMultipleDestinations = '@away/is-multiple-destinations',
  HolidaysList = '@away/holidays-list-v2',
  SelectedImages = '@away/selected-images-v2',
  DefaultImages = '@away/default-images-v2',
  ImageLocationCodes = '@away/image-location-codes-v2',
  SelectedImagesHistory = '@away/selected-images-history-v2',
  SelectedHolidayCategories = '@away/selected-holiday-categories-v2',
  SelectedLocations = '@away/selected-locations',
  SelectedPriceCategory = '@away/selected-price-category',
  SelectedBudget = '@away/selected-budget',
  Summary = '@away/summary',
  CustomSummary = '@away/custom-summary',
  IsSummaryUpdated = '@away/is-summary-updated',
  lastCompletedStep = '@away/last-completed-step',

  ExactDatesRange = '@away/exact-dates-range-v2',
  ExactDatesStart = '@away/exact-dates-start-v2',
  ExactDatesEnd = '@away/exact-dates-end-v2',

  ExactDatesDaysShift = '@away/exact-dates-days-shift-v2',
  FlexibleDatesStayDuration = '@away/flexible-dates-stay-duration-v2',
  FlexibleDatesMonthAndYear = '@away/flexible-dates-month-and-year-v2',
  IsFlexibleDatesTouched = '@away/is-flexible-dates-touched-v2',

  HotelCurrency = '@away/hotel-currency',

  RoomsForBooking = '@away/rooms-for-booking',
  SelectedCheckInDate = '@away/selected-check-in-date-v2',
  SelectedCheckOutDate = '@away/selected-check-out-date-v2',

  GuestGroups = '@away/guest-groups',

  isFlightsIncluded = '@away/is-flights-included',
  FlightType = '@away/flight-type',
  IsFlightsTestEnv = '@away/is-flights-test-env',
  FlightsIsOneWay = '@away/flights-is-one-way',
  FlightsOutboundOffer = '@away/flights-outbound-offer',
  FlightsConfirmed = '@away/flights-confirmed',
  isFlightsSelected = '@away/is-flights-selected',
  FlightsOfferFare = '@away/flights-offer-fare',
  FlightsOffers = '@away/flights-offers',
  RecommendedFlightsOffers = '@away/recommended-flights-offers',
  FlightsOptions = '@away/flights-options',
  RecommendedFlightsOptions = '@away/recommended-flights-options',
  SelectedFlightOffer = '@away/selected-flight-offer',

  SelectedFlightOfferTimeExpired = '@away/selected-flight-offer-time-expired',
  SelectedFlightOptions = '@away/selected-flight-options',
  SelectedToAirportCode = '@away/selected-to-airport-code',
  TripBooking = '@away/trip-booking',
  ContactForm = '@away/contact-form',

  SelectedDateForAllDestinations = '@away/selected-date-for-all-destinations',
  SelectedDateWhenRequestDoForItinerary = '@away/selected-date-when-request-do-for-itinerary',
  CancelledBooking = '@away/cancelled-booking',

  // todo added new fields ______________________
  SelectedRedirection = '@away/selected-redirection',
  IsLoggedInByOAuth = '@away/is-logged-in-by-oauth',
}
