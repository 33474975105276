import { sample } from 'effector'
import { popupModel } from '~/entities/popup'
import { tripsModel } from '~/entities/trip/model'
import { viewerModel } from '~/entities/viewer'
import { analyticsModel, eventNames } from '~/shared/api/analytics'
import { destinationsPageModel } from '.'

//number of first recommendations to be sent to analytics

sample({
  clock: popupModel.visiblePopupChanged,
  filter: destinationsPageModel.Gate.status,
  fn: (popup) => ({
    name:
      popup === 'register'
        ? eventNames.visitedSignUp
        : popup === 'login'
        ? eventNames.visitedLogIn
        : eventNames.visitedForgotPassword,
  }),
  target: analyticsModel.track,
})

sample({
  clock: popupModel.visiblePopupChanged,
  filter: destinationsPageModel.Gate.status,
  fn: (popup) => ({
    name:
      popup === 'register'
        ? eventNames.quizAuthVisited
        : popup === 'login'
        ? eventNames.quizAuthVisited
        : eventNames.visitedForgotPassword,
    properties: {
      type: popup === 'register' ? 'register' : 'login',
    },
  }),

  target: analyticsModel.track,
})

sample({
  clock: tripsModel.$$getRecommenderTripsFactory.getTripsFx,
  source: viewerModel.$isAuthorized,
  filter: (isAuthorized) => !isAuthorized,
  fn: () => {
    return {
      name: eventNames.quizAuthVisited,
    }
  },
  target: analyticsModel.track,
})

sample({
  clock: destinationsPageModel.destinationsViewed,
  fn: ({ page, destinations }) => ({
    name: eventNames.visitedTripsPage,
    properties: {
      page,
      destinations: destinations.map((destination) => destination.name),
      ids: destinations.map((destination) => destination.id),
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: destinationsPageModel.destinationsViewed,
  fn: ({ page, destinations }) => ({
    name: eventNames.tripsListVisited,
    properties: {
      page,
      destinations: destinations.map((destination) => destination.name),
      ids: destinations.map((destination) => destination.id),
    },
  }),
  target: analyticsModel.track,
})

sample({
  clock: destinationsPageModel.Gate.open,
  fn: () => ({
    name: eventNames.tripsListVisited,
  }),
  target: analyticsModel.track,
})

sample({
  clock: destinationsPageModel.regenerateTripsInitiated,
  fn: () => ({
    name: eventNames.initiatedRegenerateTrips,
  }),
  target: analyticsModel.track,
})

sample({
  clock: destinationsPageModel.regenerateTripsInitiated,
  fn: () => ({
    name: eventNames.tripsListRegenerated,
  }),
  target: analyticsModel.track,
})

sample({
  clock: destinationsPageModel.backToQuizNavigated,
  fn: () => ({
    name: eventNames.navigatedBackToQuiz,
  }),
  target: analyticsModel.track,
})

sample({
  clock: destinationsPageModel.openMessengerInitiated,
  fn: () => ({
    name: eventNames.clickedMessageTravelConsultant,
  }),
  target: analyticsModel.track,
})

sample({
  clock: destinationsPageModel.openMessengerInitiated,
  fn: () => ({
    name: eventNames.tripsListChatOpened,
  }),
  target: analyticsModel.track,
})
