import dayjs from 'dayjs'
import { PlusMinusVerticalIcon } from '~/shared/assets/icons/components/plus-minus-vertical'
import { formatToCustom } from '~/shared/lib/date'
import {
  DaysNearbyOption,
  MonthAndYearOption,
  SelectOption,
  StayDurationOption,
  TabBarOption,
} from './types'

export const DEFAULT_MONTH_AND_YEAR: MonthAndYearOption = {
  month: Number(dayjs().format('MM')),
  year: Number(dayjs().format('YYYY')),
}

export const generateDaysSelectOptions = (count: number): SelectOption[] => {
  const options = []

  for (let i = 1; i <= count; i++) {
    options.push({ label: i + (i > 1 ? ' days' : ' day'), value: i })
  }

  return options
}

export const generateMonthRange = (
  from: Date,
  countOfMonth: number,
): TabBarOption<MonthAndYearOption>[] => {
  const dates: TabBarOption<MonthAndYearOption>[] = []

  for (let i = 0; i <= countOfMonth; i++) {
    const date = dayjs(from).add(i, 'month')

    dates.push({
      label: date.format('MMMM YYYY'),
      option: { month: Number(date.format('MM')), year: Number(date.format('YYYY')) },
    })
  }

  return dates
}

export const daysNearbyOptions: TabBarOption<DaysNearbyOption>[] = [
  { label: 'Exact dates', option: DaysNearbyOption.EXACT_DATES },
  { label: '1 day', option: DaysNearbyOption.ONE_DAY, Icon: PlusMinusVerticalIcon },
  { label: '3 days', option: DaysNearbyOption.THREE_DAYS, Icon: PlusMinusVerticalIcon },
  { label: '5 days', option: DaysNearbyOption.FIVE_DAYS, Icon: PlusMinusVerticalIcon },
]

export const stayDurationOptions: TabBarOption<StayDurationOption>[] = [
  { label: 'Weekend', option: StayDurationOption.ONLY_WEEKEND },
  { label: '1 Week', option: StayDurationOption.WEEK },
  { label: '2 Weeks', option: StayDurationOption.TWO_WEEKS },
  { label: 'Custom', option: StayDurationOption.CUSTOM },
]

export const monthAndYearOptions: TabBarOption<MonthAndYearOption>[] = generateMonthRange(
  new Date(),
  30,
)

export const formatToCustomOrdinalDate = (date: Date, monthFormat?: string) => {
  return formatToCustom(date, 'ddd, Do MMMM YYYY')
}

export const flexibleDateOptionToStringDate = (option: MonthAndYearOption) => {
  const month = String(option.month).length < 2 ? `0${option.month}` : option.month

  return `${option.year}-${month}`
}

export const daysBetweenSelectedDates = (startDate: Date, endDate: Date) => {
  const start: Date = new Date(startDate)
  const end: Date = new Date(endDate)

  if (isNaN(start.getTime()) || isNaN(end.getTime())) {
    throw new Error('Invalid date format')
  }

  const timeDifference: number = end.getTime() - start.getTime()

  const daysDifference: number = timeDifference / (1000 * 60 * 60 * 24) + 1

  return Math.floor(daysDifference)
}
