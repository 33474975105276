import { SerializedStyles } from '@emotion/react'
import { ButtonHTMLAttributes, FC, LegacyRef } from 'react'
import { styles } from './styles'
import { ForwardedRef } from 'react'
import * as React from 'react'

export type ButtonVariant = 'default' | 'link' | 'outlined'

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant
  extendStyle?: SerializedStyles
}

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    { children, variant = 'default', extendStyle, ...buttonProps },
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <button ref={ref} css={[styles.button(variant), extendStyle]} {...buttonProps}>
      {children}
    </button>
  ),
)
