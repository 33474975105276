import { createFactory } from '@withease/factories'
import { combine, createEvent, createStore, restore, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { reset } from 'patronum'
import { GroupWithRoomsResponse, SelectedHotelRoom } from '~/entities/room'
import { getPriceAmountForRoomsPerNight } from '~/entities/room/libs'
import { getErrorMessage } from '~/shared/api'
import { LocalStorageKeys } from '~/shared/config'
import { bridge } from '~/shared/lib/factory'
import { GetRoomsFactory } from '../get-rooms'

type roomAdded = {
  room: SelectedHotelRoom
  listIndex: number
}

export const manageRoomsDataFactory = createFactory(
  ({ $$getRoomsFactory }: { $$getRoomsFactory: GetRoomsFactory }) => {
    const currencyUpdated = createEvent<string>()
    const $hotelCurrency = restore(currencyUpdated, '')

    const updateLowestTotalRateAmount = createEvent<number>()
    const $lowestTotalRateAmount = restore(updateLowestTotalRateAmount, 0)

    const updateRoomList = createEvent<GroupWithRoomsResponse[] | null>()
    const $roomList = restore<GroupWithRoomsResponse[] | null>(updateRoomList, null)
    const $isRoomsListEmpty = combine(
      $$getRoomsFactory.$groupWithRoomsList,
      (list) => !list || list?.length === 0,
    )

    const $getRoomsStatus = combine({
      error: restore($$getRoomsFactory.getRoomsFx.finally, null)
        .map(getErrorMessage)
        .reset($$getRoomsFactory.getRoomsFx),
    })

    const $selectedRooms = createStore<SelectedHotelRoom[]>([])

    const roomAdded = createEvent<roomAdded>()

    const resetSelectedRooms = createEvent()
    sample({
      clock: roomAdded,
      source: $selectedRooms,
      fn: (rooms, { room, listIndex }) => {
        const newArr: SelectedHotelRoom[] = [...rooms]
        newArr[listIndex] = room
        return newArr
      },
      target: $selectedRooms,
    })

    // mange states
    bridge(() => {
      sample({
        clock: $$getRoomsFactory.updateSelectedHotel,
        fn: (selectedHotel) => selectedHotel?.currency || '',
        target: currencyUpdated,
      })

      sample({
        clock: $$getRoomsFactory.$groupWithRoomsList,
        filter: (groupWithRoomsList) => !!groupWithRoomsList,
        fn: (selectedHotel) => {
          const copySelectedHotel = JSON.parse(JSON.stringify(selectedHotel))
          return getPriceAmountForRoomsPerNight({
            selectedHotel: copySelectedHotel,
          })
        },
        target: updateLowestTotalRateAmount,
      })

      sample({
        clock: $$getRoomsFactory.updateSelectedHotel,
        fn: (selectedHotel) => selectedHotel?.group || null,
        target: updateRoomList,
      })

      sample({
        clock: $isRoomsListEmpty,
        filter: (isRoomsListEmpty) => isRoomsListEmpty,
        target: resetSelectedRooms,
      })

      reset({
        clock: resetSelectedRooms,
        target: $selectedRooms,
      })
    })

    // handle reset
    bridge(() => {
      reset({
        clock: $$getRoomsFactory.stateReset,
        target: [
          $hotelCurrency,
          $selectedRooms,
          $lowestTotalRateAmount,
          $roomList,
          $getRoomsStatus,
        ],
      })
    })

    persist({ store: $hotelCurrency, key: LocalStorageKeys.HotelCurrency })

    return {
      $hotelCurrency,
      $selectedRooms,
      $lowestTotalRateAmount,
      $roomList,
      $isRoomsListEmpty,
      $getRoomsStatus,

      updateLowestTotalRateAmount,
      currencyUpdated,
      updateRoomList,

      roomAdded,
    }
  },
)
